<template>
  <div class="home">
    <div class="container">
      <div class="row lists-wrapper justify-content-center">
        <div class="col col-lg-8">
          <div class="app-list-container" v-if="appList.items">
            <h3>{{ appList.title }}</h3>
            <AppList :apps="appList.items"/>
          </div>
          <div class="loading-spinner-wrapper" v-else>
            <LoadingSpinner/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {Circle} from 'vue-loading-spinner'
import AppList from '@/components/AppList.vue'
import iTunes from '@/services/itunes'

const itunes = new iTunes();

export default {
  name: 'home',
  components: {
    AppList,
    LoadingSpinner: Circle
  },
  data() {
    return {
      appList: {}
    }
  },
  mounted() {
    this.loadFeed();
  },
  methods: {
    loadFeed() {
      const feedKey = this.$route.params.feedKey;

      const emptyFeed = {
        id: feedKey,
        title: '',
        items: []
      };

      this.$set(this, 'appList', emptyFeed);

      itunes
        .getFeed(feedKey)
        .then((feed) => {
          this.$set(this, 'appList', feed);
        });
    }
  },
  watch: {
    '$route.params.feedKey': function (feedKey) {
      this.loadFeed();
    }
  }
}
</script>

<style lang="scss">
.app-list-container {
  h3 {
    margin-top: 30px;
    margin-bottom: 15px;
  }
}
.loading-spinner-wrapper {
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
